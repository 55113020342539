<template>
  <section>
    <div id="kandungan">
      <div id="menu-murid">
        <h3>Tindakan</h3>
        <nav>
          <a href="#">Tambah Murid</a>
        </nav>
      </div>

      <div id="isi">
        <h2 v-if="!sudahLoadInfoHalaqah">Halaqah sedang dicari... </h2>
        <h2 v-else-if="!halaqahWujud">Halaqah ini tidak wujud</h2>
        <h2 v-else>Halaqah: {{ infoHalaqah.nama }} </h2>

        <div class="senarai-murid" v-if="sudahLoadSenaraiMurid">
          <baris-satu-murid v-for="murid in muridHalaqah" :key="murid.matrik" :murid="murid" :halaqahId="id"/>
        </div>
        <div id="belum-load-senarai-murid" v-else>
          Murid tiada
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import BarisSatuMurid from '../components/BarisSatuMurid.vue'
import { db, subSenaraiMurid } from '../firebase'
import { doc, getDoc } from 'firebase/firestore'

export default defineComponent({
  components: { BarisSatuMurid },
  data() {
    return {
      id: null,
      infoHalaqah: {
        nama: null
      },
      muridHalaqah: null,
      halaqahWujud: true,
      sudahLoadInfoHalaqah: false,
      sudahLoadSenaraiMurid: false,
      message: "",

      // pendengar
      unsub: {
        senaraiMurid: null,
      }
    }
  },
  watch: {
    muridHalaqah() {
      this.sudahLoadSenaraiMurid = true
    }
  },
  methods: {
    async dapatkanInfoHalaqah() {
      if (!this.id) return null;

      const infoHalaqahSnap = await getDoc(doc(db, 'halaqah', this.id))
      if (!infoHalaqahSnap.exists()) this.halaqahWujud = false

      const info = infoHalaqahSnap.data()
      if (info) this.sudahLoadInfoHalaqah = true
      this.infoHalaqah = info
    },
    async dapatkanMuridHalaqah() {
      if (!this.id) throw `Pastikan ID halaqah ditentukan : ${this.id}`
      const pendengarMuridHalaqah = await subSenaraiMurid(this.id)
      this.muridHalaqah = pendengarMuridHalaqah.senaraiMurid
      this.unsub.senaraiMurid = pendengarMuridHalaqah.unsubMurid
    }
  },
  created() {
    this.id = this.$route.params.id
    this.dapatkanInfoHalaqah()
    this.dapatkanMuridHalaqah()
  }
})
</script>

<style lang="scss" scoped>
#kandungan {
  display: grid;
  grid-template-columns: 250px auto;
  padding: 0;
  margin: 0;
  text-align: left;

  > * {
    margin: 10px;
    padding: 20px;
  }

  #menu-murid {
    background-color: rgb(240, 240, 240);

    h3 {
      margin: 0.2rem 0;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }

    nav {
      font-size: 0.8rem;
      margin: 1rem 0;
      
      a {
        text-decoration: none;
        color: darkcyan;
        padding: 0.5rem;

        &:hover {
          background-color: white;
        }
      }
      
    }
  }

  .senarai-murid {
    display: grid;
    grid-template-columns: auto;
  }
}
</style>