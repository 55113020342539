<template>
  <div class="satu-tasmi">
    <div class="tarikh-tasmi">
      <h6>Tasmi</h6>
      <span>{{ tarikhBerformat }}</span>
      <span class="kawalan-tasmi">
        <a @click="paparBorangSunting = !paparBorangSunting"><i class="fas fa-pen"></i></a> | 
        <a @click="confirmPadam = !confirmPadam" class="padam"><i class="fas fa-trash-alt"></i> </a>
      </span>
      <span v-if="confirmPadam" class="kawalan-confirm-padam">
        <div class="mesej-confirm-padam">Adakah anda pasti?</div>
        <div class="senarai-butang-confirm-padam">
          <a class="btn danger-box" @click="padamTasmiIniSayaPasti()">Ya, saya pasti</a>
          <a @click="confirmPadam = !confirmPadam">Tidak</a>
        </div>
      </span>
    </div>
    <div class="surah-ayat-tasmi">
      <div class="data">{{ tasmi.surah.id }} :&nbsp;
        <span v-if="tasmi.ayatAkhir == tasmi.ayatMula">{{ tasmi.ayatMula }}</span>
        <span v-else>{{ tasmi.ayatMula }}-{{ tasmi.ayatAkhir }}</span>
      </div>
      <div class="label">Surah : Ayat</div>
    </div>
    <div class="bilMukasurat-tasmi">
      <div class="data">{{ tasmi.jumlahMukasurat }}</div>
      <div class="label">Mukasurat</div>
    </div>
    <div class="kualiti-tasmi">
      <div class="data">{{ tasmi.kualitiHafazan }}%</div>
      <div class="label">Kualiti</div>
    </div>
    <div class="gred-tasmi">
      <div class="data">{{ tasmi.gredHafazan }}</div>
      <div class="label">Gred</div>
    </div>
    <div class="xp-tasmi">
      <div class="data">{{ tasmi.xp }}</div>
      <div class="label">XP</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { padamTasmi } from '../firebase'

export default defineComponent({
  props: ['halaqahId', 'murid', 'tasmi'],
  data() {
    return {
      confirmPadam: false,
      paparBorangSunting: false
    }
  },
  computed: {
    tarikhBerformat() {
      const tarikh = new Date(this.tasmi.ditasmiPada * 1000)
      return tarikh.toLocaleDateString("ms-MY", {
        weekday: 'long', month: 'long', day: 'numeric', year: 'numeric'
      })
    }
  },
  methods: {
    padamTasmi() {
      this.confirmPadam = true
    },
    padamTasmiIniSayaPasti() {
      padamTasmi(this.halaqahId, this.murid, this.tasmi, true)
    },
  }
})
</script>


<style lang="scss" scoped>
.satu-tasmi {
  display: grid;
  grid-template-columns:300px 200px 100px 100px 100px 100px;
  align-items: stretch;
  margin: 0;

  > * {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    background-color: #e0e0e0;
    margin: 5px;
    padding: 0;

    &:not(:first-child) {
      font-size: 1.2rem;
      font-weight: bold;
      display: grid;
      grid-template-rows: 65% 35%;
      border-radius: 5px;
      overflow: hidden;

      > div {
        display:flex;
        justify-content: center;
        align-items: center;

        &.data {
          padding: 0.5rem;
          background-color: skyblue;
        }

        &.label {
          width: 100%;
          height: 100%;
          font-size: 0.5rem;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          background-color: darkblue;
          color: white;
        }
      }

      &.surah-ayat-tasmi {
        > .data {
          background-color: lightgreen;
        }

        > .label {
          background-color: darkcyan;
          color: white;
        }
      }

      &.xp-tasmi {
        > .data {
          background-color: pink;
        }

        > .label {
          background-color: red;
          color: white;
        }
      }
    }
  }

  .tarikh-tasmi {
    justify-content: center;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin: 0;
      padding: 0;
      font-size: 0.8rem;
    }

    h6 {
      font-size: 1.1rem;
    }

    .kawalan-tasmi {
      margin: 0.5rem 0;
      color: #e0e0e0;

      a {
        cursor: pointer;
        &:hover {
          color: blue;
        }

        &.padam:hover {
          color: red;
        }
      }
    }

    .kawalan-confirm-padam {
      .senarai-butang-confirm-padam {
        display: grid;
        grid-template-columns: auto auto;

        a {
          display: grid;
          grid-template-columns: auto;
          justify-content: auto;
          align-items: auto;
          cursor: pointer;
          padding: 0.2rem 0.5rem;
          margin-right: 0.5rem;
          background-color: lightgreen;
          border-radius: 3px;

          &.danger-box {
            background-color: maroon;
            &:hover {
              background-color: red;
            }
          }

          &:hover {
            background-color: darkcyan;
            color: white;
          }
        }
      }
    }
  }
}
</style>