<template>
  <div class="baris-satu-murid">
    <div class="ringkasan-info">
      <div class="info">
        <h4>{{ murid.gelaran }}</h4>
        <h5>{{ murid.nama }}</h5>
        <div class="pencapaian-level">
          <bar-maju :peratus="kemajuanLevel" warna="red"></bar-maju>
          <div class="label-pencapaian-level"><b>{{ infoAntaraLevel.xpBerbaki }} xp</b> untuk naik ke <b>Level {{ infoAntaraLevel.levelAkanDatang.level }} : {{ infoAntaraLevel.levelAkanDatang.nama }}</b></div>
        </div>
        <div class="kawalan">
          <a @click="paparPerincian = !paparPerincian"><i class="fas fa-eye"></i></a> | 
          <a @click="paparBorangSuntingMaklumatMurid = !paparBorangSuntingMaklumatMurid"><i class="fas fa-pen"></i></a> | 
          <a @click="paparBorangTasmiHafazan = !paparBorangTasmiHafazan"><i class="fas fa-plus"></i></a> | 
          <a class="padam"><i class="fas fa-trash-alt"></i></a>
        </div>
      </div>
      <div class="pencapaian-murid">
        <div class="paparan-level" v-if="murid.level !== 'undefined'">
          <span class="subtitle">Level</span>
          <span class="nombor">{{ murid.level.level }} </span>
          <span class="subtitle">{{ murid.level.nama }} </span>
        </div>
        <div class="pengira-xp">
          <span class="nombor">{{ murid.jumlahXP }} </span>
          <span class="subtitle">Point XP</span>
        </div>
        <div class="pengira-tasmi">
          <span class="nombor">{{ murid.bilTasmi }} </span>
          <span class="subtitle">Bil Tasmi</span>
        </div>
      </div>
    </div>

    <div class="borang-sunting-maklumat-murid" v-if="paparBorangSuntingMaklumatMurid">
      <div class="kawalan-perincian">
        <a @click="paparBorangSuntingMaklumatMurid = false"><i class="fas fa-angle-double-up"></i> Tutup</a>
      </div>
      <div class="kandungan-perincian">
        borang-sunting-maklumat-murid :murid="murid"
      </div>
    </div>

    <div class="borang-tasmi-hafazan" v-if="paparBorangTasmiHafazan">
      <div class="kawalan-perincian">
        <a @click="paparBorangTasmiHafazan = false"><i class="fas fa-angle-double-up"></i> Tutup</a>
      </div>
      <div class="kandungan-perincian">
        <borang-tasmi-hafazan :murid="murid" :halaqahId="halaqahId" />
      </div>
    </div>

    <div class="perincian-info" v-if="paparPerincian">
      <div class="kawalan-perincian">
        <a @click="paparPerincian = false"><i class="fas fa-angle-double-up"></i> Tutup</a>
      </div>
      <div class="kandungan-perincian">
        <baris-satu-tasmi v-for="tasmi in senaraiTasmi" :key="tasmi.ditasmiPada" :tasmi="tasmi" :halaqahId="halaqahId" :murid="murid" />
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import BorangTasmiHafazan from './Borang/BorangTasmiHafazan.vue'
import BarisSatuTasmi from './BarisSatuTasmi.vue'
import BarMaju from './BarMaju.vue'
import { senaraiLevel, subSenaraiTasmi } from '../firebase'

export default defineComponent({
  components: { BorangTasmiHafazan, BarisSatuTasmi, BarMaju },
  props: ['murid', 'halaqahId'],
  data() {
    return {
      // UI
      paparBorangSuntingMaklumatMurid: false,
      paparBorangTasmiHafazan: false,
      paparPerincian: false,

      // data
      senaraiTasmi: null,

      // data listeners
      unsub: {
        senaraiTasmi: null
      }
    }
  },
  created() {
    this.subSenaraiTasmi()
  },
  watch: {
    senaraiTasmi: {
      deep: true,
      handler(val) {
        this.senaraiTasmi = val
      }
    },
  },
  computed: {
    infoAntaraLevel () {
      if (senaraiLevel === null) return {
        xpTerkumpul: null,
        xpLevelIni: null,
        xpBerbaki: null,
        levelAkanDatang: {
          level: null,
          nama: null,
        },
        levelSekarang: {
          level: null,
          nama: null,
        },
        julatAntaraDuaLevel: null,
        peratusLevelSelesai: null,
      }

      const semuaLevel = senaraiLevel.map(level => level).sort((levelA, levelB) => levelA.level - levelB.level)
      const nomborLevelSekarang = this.murid.level.level
      const levelTertinggi = semuaLevel[semuaLevel.length - 1]
      const levelSekarang = semuaLevel.filter(level => level.level == nomborLevelSekarang).pop()

      if (levelSekarang.level === levelTertinggi.level) return 100

      const xp = this.murid.jumlahXP
      const levelAkanDatang = semuaLevel.map(level => level).filter(level => level.level == (levelSekarang.level + 1)).pop()

      const julatAntaraDuaLevel = levelAkanDatang.min - levelSekarang.min
      const xpLevelIni = xp - levelSekarang.min
      const xpBerbaki = levelAkanDatang.min - xp
      const peratusLevelSelesai = xpLevelIni / julatAntaraDuaLevel

      return {
        xpTerkumpul: xp,
        xpLevelIni,
        xpBerbaki,
        levelAkanDatang,
        levelSekarang,
        julatAntaraDuaLevel,
        peratusLevelSelesai,
      }
    },
    kemajuanLevel() {
      const { peratusLevelSelesai } = this.infoAntaraLevel
      return peratusLevelSelesai * 100
    }
  },
  methods: {
    async subSenaraiTasmi() {
      const pendengarSenaraiTasmi = await subSenaraiTasmi(this.halaqahId, this.murid.matrik)
      this.unsub.senaraiTasmi = pendengarSenaraiTasmi.unsubTasmi
      this.senaraiTasmi = pendengarSenaraiTasmi.senaraiTasmi
    },
  }
})
</script>

<style lang="scss" scoped>
.baris-satu-murid {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  margin: 0;
  margin-bottom: 10px;
  padding: 1rem; 
  border-bottom: 1px solid #aaa;

  &:hover {
    background-color: #fafafa;
  }

  .ringkasan-info {
    display: grid;
    grid-template-columns: auto 70%;
    align-items: center;

    .info {
      h4 {
        margin: 0 0 0.5rem 0;
        font-size: 0.9rem;
        font-weight: 600;
      }

      h5 {
        margin: 0 0 0.5rem 0;
        font-size: 0.8rem;
        font-weight: 400;
      }

      .pencapaian-level {
        margin: 0.5rem 0;

        .label-pencapaian-level {
          text-align: right;
          font-size: 0.6rem;
          font-style: italic;
        }
      }

      .kawalan {
        color: #f0f0f0;

        a {
          color: #d0d0d0;
          cursor: pointer;

          &:hover {
            color: blue;
          }

          &.padam:hover {
            color: red;
          }
        }
      }
    }

    .pencapaian-murid {
      display: grid;
      grid-template-columns: 25% 25% 25%;
      align-items: stretch;
      justify-content: flex-end;

      > * {
        margin:10px;
        padding: 20px 40px;
        text-align: center;
        background-color: #f0f0f0;
        display: grid;
        grid-template-columns: auto;

        .nombor {
          font-size: 2rem;
          font-weight: 800;
        }

        .subtitle {
          font-size: 0.8rem;
          font-weight: 500;
        }
      }
    }
  }

  > * {
    &:not(:first-child) {
      margin: 0;
      padding: 15px 0;
      border-top: 1px solid #a0a0a0;
      font-size: 0.8rem;
      display: grid;
      grid-template-columns: 80px auto;
    }
  }

  .kawalan-perincian {
    a {
      color: darkcyan;
      line-height: 1.1rem;
      cursor: pointer;

      i {
        font-size: 1.2rem;
      }

      &:hover {
        color: blue;
      }
    }
  }
}
</style>