<template>
  <form @submit.prevent class="borang-tasmi-hafazan">
    <div class="ringkasan-maklumat">
      Maklumat Terpilih
    </div>
    <div id="berjaya-simpan" v-if="simpanBerjaya">
      Penyimpanan Berjaya!
    </div>
    <div class="maklumat-terpilih">
      <span class="surah-terpilih" v-if="f.surah">
        Surah {{ f.surah.nama }} ({{ f.surah.id }}) <a @click="f.surah = null; f.ayatMula = null; f.ayatAkhir = null;"><i class="fas fa-times"></i></a>
      </span> 
      <span class="ayat-mula-terpilih" v-if="f.ayatMula">
        daripada ayat {{ f.ayatMula }} <a @click="f.ayatMula = null; f.ayatAkhir = null;"><i class="fas fa-times"></i></a>
      </span>
      <span class="ayat-akhir-terpilih" v-if="f.ayatAkhir">
        hingga ayat {{ f.ayatAkhir }} <a @click="f.ayatAkhir = null"><i class="fas fa-times"></i></a>
      </span>
      <span class="ayat-akhir-terpilih" v-if="f.ayatAkhir">
        Jumlah Ayat : {{ f.jumlahAyat }}
      </span>
      <span class="ayat-akhir-terpilih" v-if="f.ayatAkhir">
        Jumlah Baris : {{ f.jumlahBaris }}
      </span>
      <span class="ayat-akhir-terpilih" v-if="f.ayatAkhir">
        Jumlah Mukasurat : {{ f.jumlahMukasurat }}
      </span>
    </div>

    <div class="kawalan-borang" v-if="!f.surah">
      <h5>Pilih Surah</h5>
      <div class="senarai-pilihan-surah senarai-butang">
        <a v-for="surah in senaraiSurah" :key="surah.id" @click="pilihSurah(surah)">{{ surah.nama }}</a>
      </div>
    </div>

    <div class="kawalan-borang" v-if="!f.ayatMula && f.surah">
      <h5>Pilih Ayat Mula</h5>
      <input type="text" v-model="cariAyatMula">
      <div class="senarai-pilihan-ayat-mula senarai-butang">
        <a v-for="ayat in senaraiAyatDitapis" :key="ayat" @click="pilihAyatMula(ayat)">{{ ayat }}</a>
      </div>
    </div>

    <div class="kawalan-borang" v-if="!f.ayatAkhir && f.surah && f.ayatMula">
      <h5>Pilih Ayat Akhir</h5>
      <input type="text" v-model="cariAyatAkhir">
      <div class="senarai-pilihan-ayat-akhir senarai-butang">
        <a v-for="ayat in senaraiBakiAyatDitapis" :key="ayat" @click="pilihAyatAkhir(ayat)">{{ ayat }}</a>
      </div>
    </div>

    <div class="kawalan-borang" v-if="f.ayatAkhir && f.surah && f.ayatMula">
      <h5>Penilaian</h5>
      <div class="kawalan-penilaian">
        <div class="medan-tambah-tolak">
          <a class="medan-tambah-tolak-tambah" @click="tambahSilap('hafazan')"><i class="fas fa-plus"></i></a>
          <span class="medan-tambah-tolak-kiraan">{{ f.silap.hafazan }}</span>
          <span class="medan-tambah-tolak-label" title="Kesilapan pada hafazan; salah masuk ayat, salah baris, salah huruf, salah sambung, dsb">Silap Hafaz</span>
          <a class="medan-tambah-tolak-tolak" @click="tolakSilap('hafazan')"><i class="fas fa-minus"></i></a>
        </div>
        <div class="medan-tambah-tolak">
          <a class="medan-tambah-tolak-tambah" @click="tambahSilap('jali')"><i class="fas fa-plus"></i></a>
          <span class="medan-tambah-tolak-kiraan">{{ f.silap.jali }}</span>
          <span class="medan-tambah-tolak-label" title="Kesilapan yang mengubah makna; pendekkan mad asli, tukar makhraj, dsb">Silap Jali</span>
          <a class="medan-tambah-tolak-tolak" @click="tolakSilap('jali')"><i class="fas fa-minus"></i></a>
        </div>
        <div class="medan-tambah-tolak">
          <a class="medan-tambah-tolak-tambah" @click="tambahSilap('khafi')"><i class="fas fa-plus"></i></a>
          <span class="medan-tambah-tolak-kiraan">{{ f.silap.khafi }}</span>
          <span class="medan-tambah-tolak-label" title="Kesilapan yang tidak mengubah makna; tidak dengung, tidak cukup sifat huruf, mad far'ie tidak cukup panjang, dsb">Silap Khafi</span>
          <a class="medan-tambah-tolak-tolak" @click="tolakSilap('khafi')"><i class="fas fa-minus"></i></a>
        </div>
        <div class="kiraan-skor">
          <span class="kiraan-skor-skor">{{ f.skor }}</span>
          <span class="kiraan-skor-skor-maks">{{ f.skorMaks }}</span>
        </div>
        <div class="kiraan-kualiti-hafazan">
          <span class="kiraan-kualiti-hafazan-peratus">{{ f.kualitiHafazan }}%</span>
          <span class="kiraan-kualiti-hafazan-gred">{{ f.gredHafazan }}</span>
        </div>
        <div class="status-lulus" :class="statusLulus.toLowerCase()">
          {{ statusLulus }}
        </div>
        <div class="kiraan-xp">
          <span class="kiraan-xp-pengira">{{ f.xp }}</span>
          <span class="kiraan-xp-label">XP</span>
        </div>
        <div class="simpan-penilaian" @click="simpanPenilaian">
          <i class="fas fa-save"></i>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { auth, k, hantarTasmi, dapatkanSemuaSurah } from '../../firebase'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: ['murid', 'halaqahId'],
  data() {
    return {
      // UI
      cariAyatMula: "",
      cariAyatAkhir: "",
      kadarTolak: {
        hafazan: 1,
        jali: 1,
        khafi: 0.5,
      },
      simpanBerjaya: false,

      // data
      senaraiSurah: null,

      // form
      f: {
        surah: null,
        ayatMula: null,
        ayatAkhir: null,
        jumlahAyat: null,
        jumlahBaris: null,
        jumlahMukasurat: null,
        skor: null,
        kualitiHafazan: null,
        gredHafazan: null,
        silap: {
          hafazan: null, 
          jali: null, 
          khafi: null, 
        },
        ditasmiPada: null,
        pentasmi: null
      }, 

      m: {
        level: null,
      }
    }
  },
  created(){
      this.f.pentasmi = auth.currentUser.uid
      dapatkanSemuaSurah()
        .then(senaraiSurah => this.senaraiSurah = senaraiSurah)
  },
  watch: {
    ayatAkhir(nilai) {
      if (nilai !== null) return
      this.nullifyKiraan()
    },
  },
  computed: {
    senaraiAyat() {
      if (this.f.surah === null) return null
      const beginFrom = 1
      const length = this.f.surah.bilAyat
      return Array.from({ length }, (_, i) => i + beginFrom )
    },
    senaraiAyatDitapis() {
      return this.senaraiAyat.filter(ayat => {
        return `${ayat}`.search(`${this.cariAyatMula}`) != -1
      })
    },
    senaraiBakiAyat() {
      if (this.f.surah === null) return null
      if (this.f.ayatMula === null) return null

      const beginFrom = this.f.ayatMula
      const length = this.f.surah.bilAyat - this.f.ayatMula + 1
      return Array.from({ length }, (_, i) => i + beginFrom )
    },
    senaraiBakiAyatDitapis() {
      return this.senaraiBakiAyat.filter(ayat => {
        return `${ayat}`.search(`${this.cariAyatAkhir}`) != -1
      })
    },
    statusLulus() {
      return this.f.gredHafazan == "G" ? "Gagal" : "Lulus"
    },
  },
  methods: {
    pilihSurah(surah) {
      this.f.surah = surah
    },
    pilihAyatMula(ayat) {
      this.f.ayatMula = ayat
      this.cariAyatMula = ""
    },
    async pilihAyatAkhir(ayat) {
      this.f.ayatAkhir = ayat
      this.cariAyatAkhir = ""
      
      this.f.jumlahAyat = k.kiraJumlahAyat(this.f.ayatAkhir, this.f.ayatMula)
      this.f.jumlahBaris = await k.kiraJumlahBaris(this.f.surah.id, this.f.ayatMula, this.f.ayatAkhir)
      this.f.jumlahMukasurat = k.kiraJumlahMukasurat(this.f.jumlahBaris)
      this.f.skorMaks = k.kiraSkorMaks(this.f.jumlahBaris)

      this.f.skor = this.f.skorMaks
      this.f.silap.hafazan = 0
      this.f.silap.jali = 0
      this.f.silap.khafi = 0

      this.kemaskiniStats()
    },
    tolakSilap(jenisSilap) {
      const skor = this.f.skor + this.kadarTolak[jenisSilap]
      if (skor > this.f.skorMaks) return

      const silap = this.f.silap[jenisSilap] - 1
      if (silap < 0) return

      this.f.silap[jenisSilap] = silap
      this.f.skor = skor
      this.kemaskiniStats()
    },
    tambahSilap(jenisSilap) {
      const skor = this.f.skor - this.kadarTolak[jenisSilap]
      if (skor < 0) return
      this.f.skor = skor
      this.f.silap[jenisSilap] += 1
      this.kemaskiniStats()
    },
    kemaskiniStats() {
      this.f.kualitiHafazan = k.kiraKualitiHafazan(this.f.skor, this.f.skorMaks)
      this.f.gredHafazan = k.kiraGredHafazan(this.f.kualitiHafazan, this.f.skor)
      this.f.xp = k.kiraXP(this.f.skor, this.f.kualitiHafazan, this.f.gredHafazan)
    },
    async simpanPenilaian(){
      await hantarTasmi(this.halaqahId, this.murid, this.f)
      this.nullifySemua()
      this.simpanBerjaya = true
    },
    
    nullifySemua(){
      this.nullifyKiraan()
      this.f.surah = null
      this.f.ayatMula = null
      this.f.ayatAkhir = null
      this.m.level = {
        level: null,
        nama: null,
      }
      this.simpanBerjaya = false
    },
    nullifyKiraan(){
      this.ayatDalamJulat = null
      this.f.jumlahAyat = null
      this.f.jumlahBaris = null
      this.f.skor = null
      this.f.skorMaks = null
      this.f.kualitiHafazan = null
      this.f.gredHafazan = null
      this.f.silap.hafazan = null
      this.f.silap.jali = null
      this.f.silap.khafi = null
      this.f.xp = null
      this.f.ditasmiPada = null
    },

  }
})
</script>

<style lang="scss" scoped>
.borang-tasmi-hafazan {
  .ringkasan-maklumat {
    text-transform: uppercase;
  }
  .maklumat-terpilih {
    display: flex;
    margin: 0.5rem 0;

    > * {
      margin: 0 5px 0 0;
      padding: 0.5rem 1.2rem;
      background-color: lightyellow;
      border: 1px solid darkblue;
      border-radius: 3px;
    }
  }

  .kawalan-borang {
    margin-top: 10px;

    h5 {
      font-size: 0.7rem;
      text-transform: uppercase;
      margin: 0 0 10px;
      padding: 0;
    }

    .senarai-butang {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.2rem;
        padding: 0.5rem 1.5rem;
        background-color: darkred;
        color:white;
        cursor: pointer;
      }
    }

    input {
      font-size: 1rem;
      margin: 0.5rem 0;
      padding: 0.5rem;
    }

    .kawalan-penilaian {
      display: grid;
      grid-template-columns: 100px 100px 100px 150px 200px 150px 150px 150px;
      align-items: stretch;

      .medan-tambah-tolak {
        margin: 0 10px 0 0;
        display: flex;
        flex-direction: column;
        background-color: #f0f0f0;
        border-radius: 5px;
        align-items: stretch;

        > * {
          margin:0;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          padding: 1rem;
          margin: 0.3rem;
          cursor: pointer;
          margin: 0;

          &.medan-tambah-tolak-tambah {
            background-color: red;
            color: yellow;
            border-radius: 5px 5px 0 0;
          }

          &.medan-tambah-tolak-tolak {
            background-color: lightblue;
            border-radius: 0 0 5px 5px;
          }
        }

        span {
          &.medan-tambah-tolak-kiraan {
            font-weight: 800;
            font-size: 1.8rem;
          }
          &.medan-tambah-tolak-label {
            padding-top: 0.1rem;
            text-transform: uppercase;
            font-size: 0.5rem;
          }
        }
      }

      .kiraan-skor {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: #ebebeb;
        margin: 0 10px 0 0;
        overflow: hidden;

        span {
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.5rem;
          font-weight: 800;

          &.kiraan-skor-skor-maks {
            background-color: darkblue;
            color: white;
          }
        }
      }

      .kiraan-kualiti-hafazan {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: lightgreen;
        margin: 0 10px 0 0;
        overflow: hidden;

        span {
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.5rem;
          font-weight: 800;

          &.kiraan-kualiti-hafazan-gred {
            background-color: darkcyan;
            color: white;
          }
        }
      }

      .kiraan-xp {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: lightgreen;
        margin: 0 10px 0 0;
        overflow: hidden;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 800;

          &.kiraan-xp-pengira {
            height: 80%;
            font-size: 2.5rem;
            background-color: pink;
          }
          &.kiraan-xp-label {
            font-size: 1rem;
            height: 20%;
            background-color: darkred;
            color: white;
          }
        }
      }
      .status-lulus {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        border-radius: 5px;
        margin: 0 10px 0 0;
        overflow: hidden;
        font-size: 2.2rem;

        &.lulus {
          background-color: lightgreen;
        }

        &.gagal {
          background-color: maroon;
          color: pink;
        }
      }

      .simpan-penilaian {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        border-radius: 5px;
        margin: 0 10px 0 0;
        overflow: hidden;
        font-size: 2.2rem;
        background-color: deepskyblue;
        color: darkblue;

        &:hover {
          background-color: darkblue;
          color: deepskyblue;
          cursor: pointer;
        }
      }
    }
  }
}
</style>